require('selectize/');
const queryString = require('query-string');

document.addEventListener("DOMContentLoaded", function() {

  const wl = window.location


  var links = document.querySelectorAll(".add_fields");

  links.forEach((link) => {
    link.addEventListener("click", (e) => {
      if (!link || !e) return;
      e.preventDefault();
      let time = new Date().getTime();
      let linkId = link.dataset.id;
      let regexp = linkId ? new RegExp(linkId, "g") : null;
      let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null;
      newFields ? link.insertAdjacentHTML("beforebegin", newFields) : null;
    });
  });


  if ($("#datatable_hidden_4").length){
    var Highcharts = require('highcharts');
     require('highcharts/modules/exporting')(Highcharts);  
     require('highcharts/modules/accessibility')(Highcharts);
     require('highcharts/modules/data')(Highcharts);   
     Highcharts.chart('container', {
       data: {
           table: 'datatable_hidden_4'
       },
       chart: {
           type: 'column'
       },
       title: {
           text: 'Pregled dokumenata po županijama'
       },
       xAxis: {
           type: 'category'
       },
       yAxis: {
           allowDecimals: false,
           title: {
               text: 'Iznos'
           }
       },
       plotOptions: {
         series: {
             stacking: 'normal',
             dataLabels: {
                 enabled: false
             }
         }
     },
   });
  }




  var dtable = $("#datatable_hidden")
  var dtable_3 = $("#datatable_hidden_3")
  if (dtable.length || dtable_3) {
    var Highcharts = require('highcharts');
    require('highcharts/modules/exporting')(Highcharts);  
    require('highcharts/modules/accessibility')(Highcharts);
    require('highcharts/modules/data')(Highcharts);   

    if (dtable.length) {
      Highcharts.chart('container', {
        data: {
            table: 'datatable_hidden'
        },
        chart: {
            type: 'column'
        },
        title: {
            text: 'Pregled dokumenata u danom periodu'
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            allowDecimals: false,
            title: {
                text: 'Iznos'
            }
        }
    });
    }
    if ($("#datatable_hidden_2").length){
      Highcharts.chart('container_2', {
        data: {
            table: 'datatable_hidden_2'
        },
        chart: {
            type: 'column'
        },
        title: {
            text: 'Pregled dokumenata po vijeću'
        },
        xAxis: {
            type: 'category'
        },
        colors: [
          "#feaa2c",
          "#2caffe",
          "#00e272",
          "#fe3535",
          "#fe792c",
          "#137bba",
          "#03a555",
          "#fb2c8c",
          "#c9835a",
          "#544fc5",
          "#03a5a5",
          "#fc6bc7",
          "#884c2a",
          "#8968fb",
          "#00d6d6",
          "#d35bfe"
        ],
        yAxis: {
            allowDecimals: false,
            title: {
                text: 'Iznos'
            }
        }
    });
    }

    if (dtable_3.length){
      Highcharts.chart('container', {
        data: {
            table: 'datatable_hidden_3'
        },
        chart: {
            type: 'column'
        },
        title: {
            text: 'Pregled dokumenata po vijeću',
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            allowDecimals: false,
            min: 1,
            title: {
                text: 'Iznos'
            },
            stackLabels: {
              enabled: true
          }
        },
        legend: {
          enabled: false,
          align: 'top',
          x: 70,
          verticalAlign: 'top',
          y: 0,
          floating: true,
          backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor || 'white',
          borderColor: '#CCC',
          borderWidth: 1,
          shadow: false
      },
      tooltip: {
          headerFormat: '<b>{point.x}</b><br/>',
          pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
          column: {
              stacking: 'percent',
              dataLabels: {
                  enabled: true
              }
          }
      },
    });
    
  }}
  
  $('.selectize').selectize({plugins: ['remove_button']});

  tinymce.init({
    selector: '.has_editor',
    plugins: ['image link code paste lists advlist wordcount media help'],
    menubar: false,
    height: 300,
    // formats: {
    //    subtitle: { block: 'h2' }
    //  },
    // style_formats: [
    //   { title: 'Podnaslov', format: 'subtitle' }
    // ],
    toolbar: 'undo redo | cut copy paste | bold italic | bullist numlist h2 h3 | link | image media | code help'
  });

  tinymce.init({
    selector: '.has_editor_minimal',
    plugins: ['lists wordcount textcolor'],
    menubar: false,
    height: 300,
    // formats: {
    //    subtitle: { block: 'h2' }
    //  },
    // style_formats: [
    //   { title: 'Podnaslov', format: 'subtitle' }
    // ],
    toolbar: 'bold italic | numlist | forecolor backcolor'
  });


  $(".switch").on("change", function(e){
    $(".relative_span").toggle()
  })

  var options={};

  // search settings tool - search scope
  if(document.querySelector('#searchSettings')) {
    document.getElementById("searchSettings").addEventListener("click", function(event) {
      var menu = document.querySelector('.selectorTool');
      menu.classList.toggle('open');
    });
    const $chips = document.querySelectorAll('.selectorTool > .chip');
    $chips.forEach(function($chip) {
      $chip.addEventListener("click", function(event) {
        // reset SelectorTool
        $chips.forEach(function($_chip) {
          $_chip.classList.remove('teal');
          $_chip.classList.remove('lighten-1');
          $_chip.classList.remove('white-text');
        });
        // set Selected
        this.classList.add('teal');
        this.classList.add('lighten-1');
        this.classList.add('white-text');
        document.getElementById("searchScope").value=this.dataset.id;
        wl.href = wl.pathname + '?prikaz=' + this.dataset.id;
      });
    });
  }
  // drop downs
  var elems = document.querySelectorAll('select:not(.selectize)');
  var instances = M.FormSelect.init(elems, options);
  // mobile navigation
  var elems = document.querySelectorAll('.sidenav');
  var instances = M.Sidenav.init(elems, options);
  // collapsibles
  var elems = document.querySelectorAll('.collapsible');
  var instances = M.Collapsible.init(elems);
  // datepicker
  options = {
    format: "yyyy-mm-dd",
    firstDay: 1,
    i18n:{
    cancel: "Otkaži",
    months:	["Siječanj", "Veljača", "Ožujak", "Travanj", "Svibanj", "Lipanj", "Srpanj", "Kolovoz", "Rujan", "Listopad", "Studeni", "Prosinac"],
    monthsShort:	["Sij", "Velj", "Ožu", "Tra", "Svi", "Lip", "Srp", "Kol", "Ruj", "Lis", "Stu", "Pro"],
    weekdays: ["Nedjelja", "Ponedjeljak", "Utorak", "Srijeda", "Četvrtak", "Petak", "Subota"],
    weekdaysShort: ["Ned", "Pon", "Uto", "Sri", "Čet", "Pet", "Sub"],
    weekdaysAbbrev: ["N", "P", "U", "S", "Č", "P", "S"]
  }}
  var elems = document.querySelectorAll('.datepicker');
  var instances = M.Datepicker.init(elems, options);


  // card visibility - aka sticky
  $("div.card-reveal input[type=radio], main.item aside div form input[type=radio]").change(function() {
    if (this.value == 1) {
      $("#"+$(this).attr("name")).removeClass("hide");
    }
    else {
      $("#"+$(this).attr("name")).addClass("hide");
    }
  });


  const q = queryString.parse(wl.search);
  const filters = document.querySelectorAll('.filter');
  filters.forEach(function(filter) {
    filter.addEventListener('change', function(event) {
      if (this.value) {
        q[this.name] = this.value
        wl.search = queryString.stringify(q)
      } else {
        wl.href = wl.pathname;
      }
    })
  });

  const p_id = document.getElementById("p_id");
  const cs_id = document.getElementById("cs_id");
  if (p_id && cs_id) {
    p_id.addEventListener("change", function(event) {
      if (p_id.value !== "0"){
        event.preventDefault();
        cs_id.value = "0"; 
        cs_id.dispatchEvent(new Event("change"));
    }
      });

    cs_id.addEventListener("change", function(event) {
      if (cs_id.value !== "0"){
        event.preventDefault();
        p_id.value = "0"; 
        p_id.dispatchEvent(new Event("change"));
      }
    });
  }


  const createButton = document.getElementById("addMore");
  if(createButton){
    let lastId = 0;
    createButton.addEventListener("click", (e) => {
      e.preventDefault();
      lastId = document.querySelector("#fieldsetContainer").lastElementChild.id.replace("code_group_codes_attributes_", "").replace("_id", "");
      const newId = parseInt(lastId) + 1;
      const newFieldset = document.querySelector("#fieldsetContainer fieldset").outerHTML.replaceAll("0", newId).replaceAll(/value=(...)/g, 'value=""');
      document.querySelector("#fieldsetContainer").insertAdjacentHTML(
        "beforeend", newFieldset
      );
      document.querySelector("#fieldsetContainer").insertAdjacentHTML(
        "beforeend",
        '<input type="hidden" id="code_group_codes_attributes_'+ newId+'_id"></input>'
      )
      document.getElementById("code_group_codes_attributes_"+newId+"_show_on_snv").checked = true
      document.getElementById("code_group_codes_attributes_"+newId+"_show_on_snv").value = "1"

    });
  }

  $('.advanced_search_toggle').on('click', function(){
    $('.advanced_search').slideToggle();
  });

  $('button.new_event').on('click', function(){
    $('div.new_event').slideToggle();
    $('div.edit_event').hide();
  });

  $('button.edit_event').on('click', function(){
    $('div.edit_event').slideToggle();
    $('div.new_event').hide();
  });


  $('select[name="event"]').on('change', function(){
    var event = $(this).val();
    if (event === "0"){
      $('.event_fields').hide();
    } else {
      $('.event_fields').show();
      $.ajax({
        url: 'dogadaji/' + event,
      }).done(function(data) {
        $('div.edit_event select[name="day"]').val("" + data.day);
        $('div.edit_event select[name="month"]').val("" + data.month);
        $('div.edit_event div.day input').val("" + data.day);
        $('div.edit_event div.month input').val("" + data.month);
        $('div.edit_event input[name="title"]').val(data.title);
        $('div.edit_event input#hidden_id').val(data.id);
      });
    }

    
  });


  $('.status_toggle').on('click', function(e){
    e.preventDefault()
    $(this).next().slideToggle();
  });

  $('.print').on('click', function(e){
    var url = this.getAttribute("url")
    var printWindow = window.open( url, 'Print', 'left=200, top=200, width=950, height=500, toolbar=0, resizable=0');

    printWindow.addEventListener('load', function() {
        if (Boolean(printWindow.chrome)) {
            printWindow.print();
            setTimeout(function(){
                printWindow.close();
            }, 500);
        } else {
            printWindow.print();
            printWindow.close();
        }
    }, true);
  });

  $('.calendar-holder').on('click', '.calendar_link', function(e){
    e.preventDefault();
    const id = $(this).attr('data-event-id');
    const today = $(this).attr('data-only-today');
    $.ajax({
      url: 'dogadaj/' + id,
      data: {
        today: today
      }
    }).done(function(data) {
      $('.event_holder').html(data);
    });
  });

  $('.calendar-holder').on('click', '.calendar_link', function(e){
    e.preventDefault();
    const id = $(this).attr('data-event-id');
    const today = $(this).attr('data-only-today');
    const slide_number = $(this).attr('data-slide-number');

  });

  $('.indeterminate-checkbox').on('click', function(e){
    const id = $(this).attr('data-id');
    var done = false;
     if ($(this).is(':checked')) {
       done = true;
     }
     var data = {
       model: "item",
       id: id,
       item: {is_done: done}
     }
     $.ajax({
       type: "PATCH",
       url: '/api/edit',
       data: data
     }).done(function(data) {
       if (done == true ){
         $("#row_"+id).addClass("done-item");
         $("#row_"+id).removeClass("not-done-item");
       } else{
         $("#row_"+id).addClass("not-done-item");
         $("#row_"+id).removeClass("done-item");
       }

     });

  });

  $( ".dropdown_checker" ).change(function() {
    var name = $(this).attr('name')
    var input_name = name.slice(0,-9)
    var value = $(this).val();
    if (value == 1){
      $("input#"+input_name).prop( "disabled", false );
    } else {
      $("input#"+input_name).prop( "disabled", true );
    }
  });

  $('tr.shown_row').on('click', function(){
    const id = $(this).attr('data-id')
    $(".hidden_row:not(tr[name=hidden_"+id+"])").hide();
    $("tr[name=hidden_"+id+"]").toggle(function() {
    if ($(this).is(':visible'))
        $(this).css('display','table-row');
    });
  });

  $(".hidden_row").hide();
  $( ".sort_icon" ).click(function() {
    var tbody =$('#customer_group_table');

    tbody.find('tr').sort(function(a, b) {
     if($('#name_order').val()=='asc') {
      return $('td:first', a).text().localeCompare($('td:first', b).text());
     }
     else {
      return $('td:first', b).text().localeCompare($('td:first', a).text());
     }
    }).appendTo(tbody);

    var sort_order=$('#name_order').val();
    if(sort_order=="asc"){
     document.getElementById("name_order").value="desc";
    }
    if(sort_order=="desc"){
     document.getElementById("name_order").value="asc";
    }
  });

});
