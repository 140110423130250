import React, {useState} from 'react'
import axios from 'axios'

import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import Url from '@uppy/url'
import { Dashboard } from '@uppy/react'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

const Hrvatski = require('@uppy/locales/lib/hr_HR')
const csrfToken = document.querySelector('[name=csrf-token]').content

const uppy = Uppy({
  locale: Hrvatski,
  meta: {
    id: window.location.pathname.split('/').reverse()[0],
    model: "item",
    item_id: document.getElementById("next_id").value
  },
  restrictions: { maxNumberOfFiles: 10 },
  autoProceed: false,
  allowMultipleUploads: true,
})

const xhrParams = {
  endpoint: '/api/upload',
  bundle: true,
  headers: {'X-CSRF-TOKEN': csrfToken}
}

uppy.use(XHRUpload, xhrParams)

const deleteFile = (doc, index, e) => {
  e.preventDefault()
  const id = doc.split("/")[4]
  axios.get('/api/remove_image?index=' + index + '&id=' + id + '&model=item')
  .then(function(){ location.reload() })
  .catch(function (error) { console.log(error); })
}

const Files = props => {
  let documents = ''
  if(props.documents) {
    documents = props.documents.map((doc, key) =>
      <div className="collection" key={key}>
        <button className="material-icons mdc-icon-button mdc-card__action mdc-card__action--icon right" onClick={(e) => deleteFile(doc.url, key, e)}>close</button>
        {doc.url.split("/").reverse()[0]}
      </div>
    )
  }

  return <div>
    {documents}
    <Dashboard
      uppy={uppy}
      width='100%'
      height={300}
      {...props}
    />
   </div>
}
export default Files
